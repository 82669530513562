import type { PrinterPortTypes } from './values';

export class DiscoveredPrinter {
  /** Connection interface of a device */
  readonly portType: PrinterPortTypes;
  /** Printer model name. If it could not be acquired, set blank. */
  readonly name: string | null;
  /** IP address. If it could not be acquired, set blank. */
  readonly ipAddress: string | null;
  /** MAC address. If it could not be acquired, set blank. */
  readonly macAddress: string | null;
  /** Bluetooth address. If it could not be acquired, set blank. */
  readonly bluetoothAddress: string | null;

  constructor(
    portType: PrinterPortTypes,
    name: string | null,
    ipAddress: string | null,
    macAddress: string | null,
    bluetoothAddress: string | null
  ) {
    this.portType = portType;
    this.name = name;
    this.ipAddress = ipAddress;
    this.macAddress = macAddress;
    this.bluetoothAddress = bluetoothAddress;
  }
}
