export enum InternationalCharacterType {
    Usa = 'Usa',
    France = 'France',
    Germany = 'Germany',
    UK = 'UK',
    Denmark = 'Denmark',
    Sweden = 'Sweden',
    Italy = 'Italy',
    Spain = 'Spain',
    Japan = 'Japan',
    Norway = 'Norway',
    Denmark2 = 'Denmark2',
    Spain2 = 'Spain2',
    LatinAmerica = 'LatinAmerica',
    Korea = 'Korea',
    Ireland = 'Ireland',
    Slovenia = 'Slovenia',
    Croatia = 'Croatia',
    China = 'China',
    Vietnam = 'Vietnam',
    Arabic = 'Arabic',
    Legal = 'Legal'
}