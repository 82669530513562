import { NativeEventEmitter, NativeModules } from 'react-native';
import {
  DiscoveredPrinter,
  EpsonPrinterError,
  PortType,
  PortTypes,
} from './entities';

const { EpsonEposPrinterDiscoverWrapper } = NativeModules;

type DiscovererCallback = (
  printerDiscover: PrinterDiscoverer,
  printer: DiscoveredPrinter
) => void;

export class PrinterDiscoverer {
  private readonly emitter = new NativeEventEmitter(
    EpsonEposPrinterDiscoverWrapper
  );

  private _discoveredPrinters: Array<DiscoveredPrinter> = [];

  get discoveredPrinters(): ReadonlyArray<DiscoveredPrinter> {
    return this._discoveredPrinters as ReadonlyArray<DiscoveredPrinter>;
  }

  constructor(discoverCallback: DiscovererCallback | null) {
    this.emitter.addListener('discoverPrinter', (printer) => {
      if (!printer.Target) return;

      const discoveredPrinter: DiscoveredPrinter = {
        target: printer.Target,
        deviceName: printer.DeviceName,
        ipAddress: printer.IpAddress,
        macAddress: printer.MacAddress,
        bdAddress: printer.BdAddress,
      };

      this._discoveredPrinters.push(discoveredPrinter);
      this._discoveredPrinters = this._discoveredPrinters.filter(
        (x, i, arr) => arr.findIndex((y) => y.target === x.target) === i
      );

      if (discoverCallback != null) {
        discoverCallback(this, discoveredPrinter);
      }
    });
  }

  start(portType: PortType = PortTypes.ALL): Promise<void> {
    this._discoveredPrinters = [];
    return EpsonEposPrinterDiscoverWrapper.start(portType)
      .then()
      .catch((error: any) =>
        error?.userInfo?.errorClass === 'Epos2Exception'
          ? Promise.reject(new EpsonPrinterError(error?.userInfo?.errorStatus))
          : Promise.reject(error)
      );
  }

  stop(): Promise<void> {
    return EpsonEposPrinterDiscoverWrapper.stop().then();
  }
}
