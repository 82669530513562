export const ErrorStatuses = {
  SUCCESS: 0,
  ERR_PARAM: 1,
  ERR_CONNECT: 2,
  ERR_TIMEOUT: 3,
  ERR_MEMORY: 4,
  ERR_ILLEGAL: 5,
  ERR_PROCESSING: 6,
  ERR_NOT_FOUND: 7,
  ERR_IN_USE: 8,
  ERR_TYPE_INVALID: 9,
  ERR_DISCONNECT: 10,
  ERR_ALREADY_OPENED: 11,
  ERR_ALREADY_USED: 12,
  ERR_BOX_COUNT_OVER: 13,
  ERR_BOX_CLIENT_OVER: 14,
  ERR_UNSUPPORTED: 15,
  ERR_DEVICE_BUSY: 16,
  ERR_RECOVERY_FAILURE: 17,
  ERR_FAILURE: 255,
} as const;
export type ErrorStatus = typeof ErrorStatuses[keyof typeof ErrorStatuses];

export class EpsonPrinterError extends Error {
  readonly errorStatus: ErrorStatus;

  constructor(errorStatus: ErrorStatus) {
    const message = errorMessageForErrorStatus(errorStatus);
    super(message);
    this.name = new.target.name;
    Object.setPrototypeOf(this, new.target.prototype);

    this.errorStatus = errorStatus;
  }
}

const errorMessageForErrorStatus = (errorStatus: ErrorStatus) => {
  switch (errorStatus) {
    case ErrorStatuses.ERR_PARAM:
      return '不正なパラメーターが渡されました。';
    case ErrorStatuses.ERR_CONNECT:
      return 'デバイスとの通信に失敗しました。';
    case ErrorStatuses.ERR_TIMEOUT:
      return 'デバイスと通信がタイムアウトしました。';
    case ErrorStatuses.ERR_MEMORY:
      return 'メモリの確保二失敗しました。';
    case ErrorStatuses.ERR_ILLEGAL:
      return '不適切な方法で使用されました。';
    case ErrorStatuses.ERR_PROCESSING:
      return '他の処理が実行中です。';
    case ErrorStatuses.ERR_NOT_FOUND:
      return 'プリンタが見つかりませんでした。';
    case ErrorStatuses.ERR_IN_USE:
      return 'プリンタが他の処理で使用中です。';
    case ErrorStatuses.ERR_TYPE_INVALID:
      return 'プリンタの機種または接続方法が正しくありません。';
    case ErrorStatuses.ERR_DISCONNECT:
      return 'プリンタの切断に失敗しました。';
    case ErrorStatuses.ERR_ALREADY_OPENED:
      return 'すでにプリンタと通信中です。';
    case ErrorStatuses.ERR_ALREADY_USED:
      return 'プリンタのメンバーIDが使用中です。';
    case ErrorStatuses.ERR_BOX_COUNT_OVER:
      return 'これ以上プリンタのコミュニケーションボックスを作成できません。';
    case ErrorStatuses.ERR_BOX_CLIENT_OVER:
      return 'これ以上プリンタのコミュニケーションボックスのメンバーを作成できません。';
    case ErrorStatuses.ERR_UNSUPPORTED:
      return 'サポートしていないプリンタ機種です。';
    case ErrorStatuses.ERR_DEVICE_BUSY:
      return 'プリンタが応答していません。';
    case ErrorStatuses.ERR_RECOVERY_FAILURE:
      return 'プリンタの復旧に失敗しました。';
    case ErrorStatuses.ERR_FAILURE:
      return `不明なエラーが発生しました。 エラーコード: ${errorStatus}`;
    default:
      return `不明なエラーが発生しました。 エラーコード: ${errorStatus}`;
  }
};
