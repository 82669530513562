import { NativeEventEmitter, NativeModules } from 'react-native';
import { DiscoveredPrinter } from './discovered_printer';

const { SiiPrinterDiscoverWrapper } = NativeModules;

type DiscoverCallback = (
  printerDiscover: PrinterDiscover,
  printer: DiscoveredPrinter
) => void;

export class PrinterDiscover {
  private readonly emitter = new NativeEventEmitter(SiiPrinterDiscoverWrapper);

  private _discoveredPrinters: Array<DiscoveredPrinter> = [];

  get discoveredPrinters(): ReadonlyArray<DiscoveredPrinter> {
    return this._discoveredPrinters as ReadonlyArray<DiscoveredPrinter>;
  }

  constructor(discoverCallback: DiscoverCallback | null) {
    this.emitter.addListener('discoverPrinter', (printer) => {
      const discoveredPrinter = new DiscoveredPrinter(
        printer.PortType,
        printer.Name,
        printer.IpAddress,
        printer.MacAddress,
        printer.BluetoothAddress
      );

      this._discoveredPrinters.push(discoveredPrinter);
      this._discoveredPrinters = this._discoveredPrinters.filter(
        (x, i, arr) =>
          arr.findIndex(
            (y) =>
              y.portType === x.portType &&
              y.ipAddress === x.ipAddress &&
              y.macAddress === x.macAddress &&
              y.bluetoothAddress === x.bluetoothAddress
          ) === i
      );

      if (discoverCallback != null) {
        discoverCallback(this, discoveredPrinter);
      }
    });
  }

  start(): Promise<void> {
    this._discoveredPrinters = [];
    return SiiPrinterDiscoverWrapper.start();
  }

  stop(): Promise<void> {
    return SiiPrinterDiscoverWrapper.stop();
  }
}
