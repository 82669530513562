export enum Pdf417Level {
    Ecc0 = 'Ecc0',
    Ecc1 = 'Ecc1',
    Ecc2 = 'Ecc2',
    Ecc3 = 'Ecc3',
    Ecc4 = 'Ecc4',
    Ecc5 = 'Ecc5',
    Ecc6 = 'Ecc6',
    Ecc7 = 'Ecc7',
    Ecc8 = 'Ecc8'
}