export enum BarcodeSymbology {
    UpcE = 'UpcE',
    UpcA = 'UpcA',
    Jan8 = 'Jan8',
    Ean8 = 'Ean8',
    Jan13 = 'Jan13',
    Ean13 = 'Ean13',
    Code39 = 'Code39',
    Itf = 'Itf',
    Code128 = 'Code128',
    Code93 = 'Code93',
    NW7 = 'NW7'
}