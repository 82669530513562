import { NativeModules } from 'react-native';
import type { DiscoveredPrinter } from './discovered_printer';
import { Ditherings, PrinterModels, PrinterPortTypes } from './values';

const { SiiPrinterWrapper } = NativeModules;

export class Printer {
  private readonly portType: PrinterPortTypes;
  private readonly model: PrinterModels;
  private readonly name: string | null;
  private readonly ipAddress: string | null;
  private readonly bluetoothAddress: string | null;

  constructor(
    portType: PrinterPortTypes,
    model: PrinterModels,
    name?: String,
    ipAddress?: string,
    bluetoothAddress?: string
  );

  constructor(printer: DiscoveredPrinter, model: PrinterModels);

  constructor(
    obj: PrinterPortTypes | DiscoveredPrinter,
    model: PrinterModels,
    name?: string,
    ipAddress?: string,
    bluetoothAddress?: string
  ) {
    if (typeof obj === 'number') {
      this.portType = obj;
      this.model = model;
      this.name = name || null;
      this.ipAddress = ipAddress || null;
      this.bluetoothAddress = bluetoothAddress || null;
    } else {
      this.portType = obj.portType;
      this.model = model;
      this.name = obj.name;
      this.ipAddress = obj.ipAddress;
      this.bluetoothAddress = obj.bluetoothAddress;
    }
  }

  async printImage(
    data: string,
    dithering: Ditherings = Ditherings.DISABLE,
    brightness: number = 1.0,
    autoCut: boolean = true,
    bottomMargin: number = 80
  ): Promise<void> {
    return await SiiPrinterWrapper.printImage(
      this.portType,
      this.model,
      this.name,
      this.ipAddress,
      this.bluetoothAddress,
      data,
      dithering,
      brightness,
      autoCut,
      bottomMargin
    );
  }

  async dispose() {
    await SiiPrinterWrapper.dispose();
  }
}
