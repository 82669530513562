export const PrinterModels = {
  DPU_S445: 281,
  DPU_S245: 284,
  RP_E10: 291,
  RP_D10: 295,
  MP_B20: 298,
  MP_B30: 302,
  RP_F10: 301,
  MP_B30L: 304,
} as const;
export type PrinterModels = typeof PrinterModels[keyof typeof PrinterModels];

export const PrinterPortTypes = {
  BLUETOOTH: 0,
  USB: 1,
  TCP: 2,
} as const;
export type PrinterPortTypes =
  typeof PrinterPortTypes[keyof typeof PrinterPortTypes];

export function getPrinterPortName(portType: PrinterPortTypes): String {
  switch (portType) {
    case PrinterPortTypes.BLUETOOTH:
      return 'BLUETOOTH';
    case PrinterPortTypes.USB:
      return 'USB';
    case PrinterPortTypes.TCP:
      return 'TCP';
  }
}

export const Ditherings = {
  DISABLE: 0,
  ERRORDIFFUSION: 1,
} as const;
export type Ditherings = typeof Ditherings[keyof typeof Ditherings];
