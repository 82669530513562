export type Option = {
  color?: Color;
  mode?: Mode;
  halftone?: Halftone;
  brightness?: number;
  compress?: Compress;
  align?: Align;
  autoCut?: boolean;
};

export const Colors = {
  NONE: 0,
  COLOR_1: 1,
  COLOR_2: 2,
  COLOR_3: 3,
  COLOR_4: 4,
} as const;
export type Color = typeof Colors[keyof typeof Colors];

export const Modes = {
  MONO: 0,
  GRAY16: 1,
  MONO_HIGH_DENSITY: 2,
} as const;
export type Mode = typeof Modes[keyof typeof Modes];

export const Halftones = {
  DITHER: 0,
  ERROR_DIFFUSION: 1,
  THRESHOLD: 2,
} as const;
export type Halftone = typeof Halftones[keyof typeof Halftones];

export const Compresses = {
  DEFLATE: 0,
  NONE: 1,
  AUTO: 2,
} as const;
export type Compress = typeof Compresses[keyof typeof Compresses];

export const Aligns = {
  LEFT: 0,
  CENTER: 1,
  RIGHT: 2,
} as const;
export type Align = typeof Aligns[keyof typeof Aligns];

export const PortTypes = {
  ALL: 0,
  TCP: 1,
  BLUETOOTH: 2,
  USB: 3,
} as const;
export type PortType = typeof PortTypes[keyof typeof PortTypes];

export const DefaultParam = -2;
export type DefaultParam = -2;
