import { NativeModules } from 'react-native';
import {
  DefaultParam,
  Dimension,
  EpsonPrinterError,
  Model,
  Option,
} from './entities';

const { EpsonEposPrinterWrapper } = NativeModules;

export const printImage = (
  target: string,
  printerModel: Model,
  data: string,
  dimension?: Dimension,
  option?: Option
): Promise<void> =>
  EpsonEposPrinterWrapper.printImage(
    target,
    printerModel,
    data,
    dimension?.x ?? 0,
    dimension?.y ?? 0,
    dimension?.width ?? DefaultParam,
    dimension?.height ?? DefaultParam,
    option?.color ?? DefaultParam,
    option?.mode ?? DefaultParam,
    option?.halftone ?? DefaultParam,
    option?.brightness ?? DefaultParam,
    option?.compress ?? DefaultParam,
    option?.align ?? DefaultParam,
    option?.autoCut ?? true
  )
    .then()
    .catch((error: any) =>
      error?.userInfo?.errorClass === 'Epos2Exception'
        ? Promise.reject(new EpsonPrinterError(error?.userInfo?.errorStatus))
        : Promise.reject(error)
    );

export const dispose = (target: string): Promise<void> =>
  EpsonEposPrinterWrapper.dispose(target);
