export const Models = {
  TM_M10: 0,
  TM_M30: 1,
  TM_P20: 2,
  TM_P60: 3,
  TM_P60II: 4,
  TM_P80: 5,
  TM_T20: 6,
  TM_T60: 7,
  TM_T70: 8,
  TM_T81: 9,
  TM_T82: 10,
  TM_T83: 11,
  TM_T88: 12,
  TM_T90: 13,
  TM_T90KP: 14,
  TM_U220: 15,
  TM_U330: 16,
  TM_L90: 17,
  TM_H6000: 18,
  TM_T83III: 19,
  TM_T100: 20,
  TM_M30II: 21,
  TS_100: 22,
  TM_M50: 23,
} as const;
export type Model = typeof Models[keyof typeof Models];
